import React from "react"


const EmailForm = () => {
  return (
    <section className="p-6 md:mx-auto md:max-w-4xl flex flex-col items-center place-content-center">
        <h1 className="font-medium text-xl text-center mb-4">You like the post? Let's be friends! I might e-mail you sometime</h1>
        <form name="lap-email-gather" data-netlify="true" data-netlify-honeypot="bot-field" className="email-form" method="post">
            <input type="hidden" name="form-name" value="lap-email-gather" />
            <label>
                <input className="font-extrabold p-2 w-full mb-4 border-b-4 border-red-900 bg-gray-100 text-lg" name="email" type="email" size="55" placeholder="igetspammed@gmail.com" required></input>
            </label>
            <button className="btn-cta">Start our friendship!</button>
        </form>
    </section>
  )
}

export default EmailForm
