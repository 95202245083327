import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailForm from "../components/emailForm"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="px-8 mx-auto prose prose-sm sm:prose lg:prose-lg xl:prose-xl"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>
            {post.frontmatter.date}<br />
            <small>{post.frontmatter.readingTimeInMinutes} min read</small>
          </p>
          <blockquote>{post.frontmatter.description}</blockquote>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr className="my-8" />
      </article>
      <footer className="md:max-w-2xl lg:max-w-4xl md:mx-auto">
        <Bio />
        <EmailForm />
      </footer>
      <nav className="my-6 md:max-w-4xl md:mx-auto">
        <div className="flex w-full place-content-around md:mx-auto md:max-w-4xl">
          {previous && (
            <Link className="text-center w-48 rounded-lg px-2 py-6 hover:bg-gray-300 hover:border-2 hover:border-gray-500 sm:w-64 md:w-max" to={previous.fields.slug} rel="prev">
              ←  <span className="inline-block md:hidden">Prev</span><span className="hidden md:inline-block">{previous.frontmatter.title}</span>
            </Link>
          )}
          {next && (
            <Link className="text-center w-48 rounded-lg px-2 py-6 hover:bg-gray-300 hover:border-2 hover:border-gray-500 sm:w-64 md:w-max" to={next.fields.slug} rel="next">
              <span className="inline-block md:hidden">Next</span><span className="hidden md:inline-block">{next.frontmatter.title}</span> →
            </Link>
          )}
        </div>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        readingTimeInMinutes
      }
    }
  }
`
